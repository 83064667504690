import {
  deepClone,
  findAccountById,
  findFundById,
} from "../../../utilities/general_util";

// Function to create a map of transactions organized by account and fund
const createTransactionMap = (transactions) => {
  const transactionMap = {};

  transactions.forEach((tx) => {
    tx.lines.forEach((line) => {
      // Create a key based on account and fund
      const key = `${line.account}-${line.fund}`;
      if (!transactionMap[key]) {
        transactionMap[key] = [];
      }
      if (!transactionMap[key].includes(tx)) {
        transactionMap[key].push(tx);
      }
    });
  });

  return transactionMap;
};

const combineFundColumns = ({
  fundColumnsObject1,
  fundColumnsObject2,
  subtract = false,
}) => {
  if (fundColumnsObject1.length !== fundColumnsObject2.length) {
    console.error(
      "Error: fundColumnsObject1 and fundColumnsObject2 must be the same length",
    );
    return;
  }

  let combinedColumns = [];
  fundColumnsObject1.forEach((fundGroup, groupIndex) => {
    let combinedFundGroup = deepClone(fundGroup);
    const fundGroup2 = fundColumnsObject2[groupIndex];

    // Determine the operation (addition or subtraction) based on the 'subtract' flag
    const operation = subtract ? (a, b) => a - b : (a, b) => a + b;

    if (combinedFundGroup?.funds?.length > 0) {
      // Apply the operation to the fund group totals and combine relevantLines
      combinedFundGroup.total = operation(
        combinedFundGroup.total,
        fundGroup2.total,
      );
      combinedFundGroup.relevantLines = combinedFundGroup.relevantLines.concat(
        fundGroup2.relevantLines,
      );

      combinedFundGroup.funds.forEach((fund, fundIndex) => {
        const fund2 = fundGroup2.funds[fundIndex];
        fund.total = operation(fund.total, fund2.total);
        fund.relevantLines = fund.relevantLines.concat(fund2.relevantLines);

        if (fund.type === "parentFund" && fund2.subFunds?.length > 0) {
          fund.subFunds.forEach((subFund, index) => {
            const subFund2 = fund2.subFunds[index];
            subFund.total = operation(subFund.total, subFund2.total);
            subFund.relevantLines = subFund.relevantLines.concat(
              subFund2.relevantLines,
            );
          });
          fund.withSubFundsTotal = operation(
            fund.withSubFundsTotal,
            fund2.withSubFundsTotal,
          );
          fund.relevantParentLines = fund.relevantParentLines.concat(
            fund2.relevantParentLines,
          );
        }
      });
    } else {
      // Apply the operation to total rows and combine relevantLines
      combinedFundGroup.total = operation(
        combinedFundGroup.total,
        fundGroup2.total,
      );
      combinedFundGroup.relevantLines = combinedFundGroup.relevantLines.concat(
        fundGroup2.relevantLines,
      );
    }

    combinedColumns.push(combinedFundGroup);
  });

  return combinedColumns;
};

// Updated collectRelevantLinesV2 function to operate on entire transactions
export const collectRelevantLinesV2 = ({ transactionMap, account, fund }) => {
  const key = `${account}-${fund}`;
  const relevantTransactions = transactionMap[key] || [];

  // Classify each transaction as "simple" or "complex"
  relevantTransactions.forEach((tx) => {
    const accountLine = tx.lines.filter((line) => line.account === account);
    const otherLines = tx.lines.filter((line) => line.account !== account);
    const totalAmountOtherLines = otherLines.reduce(
      (acc, line) => acc + parseInt(line.amount),
      0,
    );
    const totalAmountTheseLines = accountLine.reduce(
      (acc, line) => acc + parseInt(line.amount),
      0,
    );
    tx.type =
      totalAmountOtherLines === totalAmountTheseLines ? "simple" : "complex";
  });

  // Return relevant transactions with filtered lines if needed
  return relevantTransactions.flatMap((tx) => {
    if (tx.type === "simple") {
      return tx.lines
        .filter((line) => line.account !== account)
        .map((line, index) => ({
          amount: line.amount,
          date: tx.date,
          contact: line.contact,
          id: tx.id,
          line: index,
          sign: line.sign,
          fund: line.fund,
          type: "simple",
          account: line.account,
        }));
    } else {
      return {
        date: tx.date,
        id: tx.id,
        lines: tx.lines,
        type: "complex",
      };
    }
  });
};

// Updated calculateAccountTotal function using the transaction map
const calculateAccountTotal = ({
  transactionMap,
  account,
  fund,
  isCreditPositive,
}) => {
  const key = `${account}-${fund}`;
  const filteredTransactions = transactionMap[key] || [];

  if (filteredTransactions.length === 0) {
    return 0;
  }

  const total = filteredTransactions.reduce((acc, tx) => {
    const sum = tx.lines.reduce((lineAcc, line) => {
      if (line.account === account && line.fund === fund) {
        const amount = parseInt(line.amount, 10);
        return (
          lineAcc +
          (line.sign === "credit"
            ? isCreditPositive
              ? amount
              : -amount
            : isCreditPositive
              ? -amount
              : amount)
        );
      }
      return lineAcc;
    }, 0);
    return acc + sum;
  }, 0);

  return total;
};

// Function to sum transactions by fund hierarchy using the transaction map
const sumTransactionsByFundHierarchy = ({
  transactionMap,
  fundsHierarchy,
  funds,
  accountId,
  isCreditPositive,
}) => {
  let totalForAccount = 0;
  let relevantLinesForAccount = [];
  const fundGroupsArr = [];

  fundsHierarchy.groups.forEach((fundGroupObj) => {
    let fundObjects = [];
    const fundGroupNameString = fundGroupObj.groupName;
    let totalForGroup = 0;
    let relevantLinesForGroup = [];

    fundGroupObj.funds.forEach((fund) => {
      const fundInfo = findFundById({
        funds,
        fundId: fund.id,
      });
      const fundName = fundInfo.fundName;
      const fundNumber = fundInfo.fundNumber;

      // Find relevant lines and total for the fund using the map
      const relevantLines = collectRelevantLinesV2({
        transactionMap,
        account: accountId,
        fund: fund.id,
      });

      let relevantParentLines = deepClone(relevantLines); // Clone to avoid modifying the original

      const fundTotal = calculateAccountTotal({
        transactionMap,
        account: accountId,
        fund: fund.id,
        isCreditPositive,
      });

      let fundTotalWithSubFunds = fundTotal;
      const subFunds = fund.subFunds;
      let subFundsColumns = [];

      if (subFunds?.length > 0) {
        subFunds.forEach((subFundId) => {
          const subFundInfo = findFundById({
            funds,
            fundId: subFundId,
          });
          const subFundName = subFundInfo.fundName;
          const subFundNumber = subFundInfo.fundNumber;

          // Find relevant lines and total for subFunds
          const relevantSubFundLines = collectRelevantLinesV2({
            transactionMap,
            account: accountId,
            fund: subFundId,
          });

          // Accumulate relevant lines from subFunds into the parent
          relevantParentLines =
            relevantParentLines.concat(relevantSubFundLines);

          const subFundTotal = calculateAccountTotal({
            transactionMap,
            account: accountId,
            fund: subFundId,
            isCreditPositive,
          });

          fundTotalWithSubFunds += subFundTotal;

          // Add subFund columns
          subFundsColumns.push({
            fundName: subFundName,
            fundNumber: subFundNumber,
            total: subFundTotal,
            relevantLines: relevantSubFundLines,
            type: "subFund",
          });
        });

        // Add parent fund column (with the accumulated relevant lines from subFunds)
        fundObjects.push({
          fundName,
          fundNumber,
          total: fundTotal,
          withSubFundsTotal: fundTotalWithSubFunds,
          relevantLines, // Original relevant lines of the parent
          relevantParentLines, // Combined relevant lines including subFunds
          subFunds: subFundsColumns,
          type: "parentFund",
        });

        // Update the group total and accumulate relevant lines for the group
        totalForGroup += fundTotalWithSubFunds;
        relevantLinesForGroup =
          relevantLinesForGroup.concat(relevantParentLines);
      } else {
        // No subFunds, add the fund column
        fundObjects.push({
          fundName,
          fundNumber,
          total: fundTotal,
          relevantLines, // Original relevant lines without subFunds
          type: "fund",
        });

        // Update the group total and accumulate relevant lines for the group
        totalForGroup += fundTotal;
        relevantLinesForGroup = relevantLinesForGroup.concat(relevantLines);
      }
    });

    // Add group column with the accumulated relevant lines
    fundGroupsArr.push({
      fundGroupName: fundGroupNameString,
      total: totalForGroup,
      relevantLines: relevantLinesForGroup,
      type: "fundGroup",
      funds: fundObjects,
    });

    // Update the account total and accumulate relevant lines for the account
    totalForAccount += totalForGroup;
    relevantLinesForAccount = relevantLinesForAccount.concat(
      relevantLinesForGroup,
    );
  });

  // Add account column with the total and relevant lines for the account
  fundGroupsArr.push({
    total: totalForAccount,
    relevantLines: relevantLinesForAccount,
    type: "allFundsTotal",
  });

  // Return a deep clone of the fund groups array
  const fullFundsArrToReturn = deepClone(fundGroupsArr);
  return fullFundsArrToReturn || [];
};

// Main calculation function using the transaction map
export const calcReportV2 = ({
  filteredTransactions,
  accountsHierarchy,
  fundsHierarchy,
  accounts,
  funds,
  type,
}) => {
  // Create the transaction map once to use for all calculations
  const transactionMap = createTransactionMap(filteredTransactions);
  const reportAccountsArr = [];

  const getAllIds = (input) => {
    if (Array.isArray(input)) {
      return input.flatMap((item) => getAllIds(item));
    }
    if (input && typeof input === "object") {
      if (input.groups) {
        return getAllIds(input.groups);
      }
      if (input.accounts) {
        return getAllIds(input.accounts);
      }
      if (input.id) {
        return [
          input.id,
          ...(input.subAccounts ? getAllIds(input.subAccounts) : []),
        ];
      }
    }
    if (typeof input === "string") {
      return [input];
    }
    return [];
  };

  accountsHierarchy.types
    .filter(
      (typeObj) =>
        (type === "balanceSheet" &&
          (typeObj.type.toLowerCase() === "assets" ||
            typeObj.type.toLowerCase() === "liabilities")) ||
        (type === "incomeStatement" &&
          (typeObj.type.toLowerCase() === "income" ||
            typeObj.type.toLowerCase() === "expenses")),
    )
    .forEach((accountTypeObj) => {
      const accountTypeNameString = accountTypeObj.type;
      let totalForAccountType = [];
      const typeAccounts = [];
      const typeAccountIds = getAllIds(accountTypeObj);

      accountTypeObj.groups.forEach((accountGroupObj, groupIndex) => {
        const accountGroupNameString = accountGroupObj.groupName;
        let totalForAccountGroup = [];
        const groupAccounts = [];
        const groupAccountIds = getAllIds(accountGroupObj);

        accountGroupObj.accounts.forEach((account, accountIndex) => {
          const accountInfo = findAccountById({
            accounts,
            accountId: account.id,
          });
          const accountName = accountInfo.accountName;
          const accountNumber = accountInfo.accountNumber;
          const isCreditPositive =
            accountInfo.accountType.toLowerCase() === "liabilities" ||
            accountInfo.accountType.toLowerCase() === "income";

          // Find totals for account by fund and total for the whole account
          let accountsFundColumns = sumTransactionsByFundHierarchy({
            transactionMap,
            fundsHierarchy,
            funds,
            accountId: account.id,
            isCreditPositive,
          });

          let accountTotalWithSubAccounts = deepClone(accountsFundColumns);
          const subAccounts = account.subAccounts;
          const subAccountsRows = [];

          if (subAccounts?.length > 0) {
            subAccounts.forEach((subAccountId) => {
              const subAccountInfo = findAccountById({
                accounts,
                accountId: subAccountId,
              });
              const subAccountName = subAccountInfo.accountName;
              const subAccountNumber = subAccountInfo.accountNumber;

              // Find totals for subAccounts by fund and total to sum up into the parent
              let subAccountFundColumns = sumTransactionsByFundHierarchy({
                transactionMap,
                fundsHierarchy,
                funds,
                accountId: subAccountId,
                isCreditPositive,
              });

              // Combine totals and relevantLines from subAccounts into the parent
              accountTotalWithSubAccounts = combineFundColumns({
                fundColumnsObject1: accountTotalWithSubAccounts,
                fundColumnsObject2: subAccountFundColumns,
              });

              subAccountsRows.push({
                accountName: subAccountName,
                accountNumber: subAccountNumber,
                columns: subAccountFundColumns,
                type: "subAccount",
              });
            });

            groupAccounts.push({
              accountName,
              accountNumber,
              columns: accountsFundColumns,
              subAccounts: subAccountsRows,
              type: "parentAccount",
              totalRowColumns: accountTotalWithSubAccounts,
            });
          } else {
            groupAccounts.push({
              accountName,
              accountNumber,
              columns: accountsFundColumns,
              type: "account",
            });
          }

          if (accountIndex === 0) {
            totalForAccountGroup = deepClone(accountTotalWithSubAccounts);
          } else {
            totalForAccountGroup = combineFundColumns({
              fundColumnsObject1: totalForAccountGroup,
              fundColumnsObject2: accountTotalWithSubAccounts,
            });
          }
        });

        typeAccounts.push({
          accountGroupName: accountGroupNameString,
          columns: totalForAccountGroup,
          accounts: groupAccounts,
          type: "accountGroup",
        });

        if (groupIndex === 0) {
          totalForAccountType = deepClone(totalForAccountGroup);
        } else {
          totalForAccountType = combineFundColumns({
            fundColumnsObject1: totalForAccountType,
            fundColumnsObject2: totalForAccountGroup,
          });
        }
      });

      reportAccountsArr.push({
        accountTypeName: accountTypeNameString,
        columns: totalForAccountType,
        groups: typeAccounts,
        type: "accountType",
      });
    });

  if (type === "balanceSheet") {
    const totalAssets = reportAccountsArr.find(
      (accountType) => accountType.accountTypeName.toLowerCase() === "assets",
    );
    const totalLiabilities = reportAccountsArr.find(
      (accountType) =>
        accountType.accountTypeName.toLowerCase() === "liabilities",
    );

    // If there are no assets, nothing to do
    if (!totalAssets?.columns?.length) {
      return reportAccountsArr;
    }

    // Create a clone of totalAssets for equity
    const totalEquity = deepClone(totalAssets);

    // If liabilities exist, use combineFundColumns
    // otherwise, just set equity columns equal to assets columns
    if (totalLiabilities?.columns?.length) {
      totalEquity.columns = combineFundColumns({
        fundColumnsObject1: totalAssets.columns,
        fundColumnsObject2: totalLiabilities.columns,
        subtract: true, // assets - liabilities
      });
    } else {
      // No liabilities => equity = assets
      totalEquity.columns = deepClone(totalAssets.columns);
    }

    reportAccountsArr.push({
      accountTypeName: "Equity",
      columns: totalEquity.columns,
      type: "accountType",
    });
  }

  if (type === "incomeStatement") {
    const totalIncome = reportAccountsArr.find(
      (accountType) => accountType.accountTypeName.toLowerCase() === "income",
    );
    const totalExpenses = reportAccountsArr.find(
      (accountType) => accountType.accountTypeName.toLowerCase() === "expenses",
    );

    if (
      totalIncome?.columns?.length > 0 &&
      totalExpenses?.columns?.length > 0
    ) {
      const totalNetIncome = deepClone(totalIncome);
      totalNetIncome.columns = combineFundColumns({
        fundColumnsObject1: totalIncome.columns,
        fundColumnsObject2: totalExpenses.columns,
        subtract: true,
      });
      reportAccountsArr.push({
        accountTypeName: "Net Income",
        columns: totalNetIncome.columns,
        type: "accountType",
      });
    }
  }

  return reportAccountsArr;
};
